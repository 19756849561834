/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import TypographyWithIcon from "@openup/shared/components/TypographyWithIcon/TypographyWithIcon";
import {
  BoltOutlined,
  FavoriteBorderOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  NightlightOutlined,
  PeopleAltOutlined,
  SpaOutlined,
  ThumbUpOutlined,
  VpnKeyOutlined,
  WorkOutline,
} from "@mui/icons-material";
import { useQueryThemesCheckins } from "@src/queries/themes";
import { THEME_KEY } from "@src/utils/constants";
import { Popover } from "@src/components/Popover";

const getThemeIcons = (themeKey: string) => {
  switch (themeKey) {
    case THEME_KEY.CONFIDENCE:
      return <ThumbUpOutlined className="scale-90" />;
    case THEME_KEY.STRESS:
      return <BoltOutlined className="scale-90" />;
    case THEME_KEY.HEALTH:
      return <FavoriteBorderOutlined className="scale-90" />;
    case THEME_KEY.WORK:
      return <WorkOutline className="scale-90" />;
    case THEME_KEY.PURPOSE:
      return <VpnKeyOutlined className="scale-90" />;
    case THEME_KEY.RELATIONSHIPS:
      return <PeopleAltOutlined className="scale-90" />;
    case THEME_KEY.SLEEP:
      return <NightlightOutlined className="scale-90" />;
    default:
      return <SpaOutlined className="scale-90" />;
  }
};

type Theme = {
  key: string;
  isFocused: boolean;
  name?: string;
};

export const ThemesDropdownMobile = ({
  closeMenu,
  activeTheme,
  setActiveTheme,
}: {
  closeMenu: () => void;
  activeTheme: string | null;
  setActiveTheme: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const { t, pathT } = useTranslatedNavigate();
  const { data: themes = [] } = useQueryThemesCheckins() as {
    data: Theme[] | undefined;
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleOnClick = () => setIsOpen(!isOpen);

  const handleThemeClick = (themeKey: string) => {
    setActiveTheme(themeKey);
    closeMenu();
  };

  const renderThemes = (themeList) =>
    themeList.map((theme) => (
      <RouterLink
        key={theme.key}
        className="w-full text-indigo-800"
        to={pathT("route.themes", {
          key: t(`route.ThemeKey.${theme.key}`),
        })}
        onClick={() => handleThemeClick(theme.key)}
      >
        <TypographyWithIcon
          icon={getThemeIcons(theme.key)}
          classNameText="ml-3"
          className="flex items-center w-full"
        >
          <p className="font-semibold">{t(`Themes.${theme.key}.Name`)}</p>
        </TypographyWithIcon>
      </RouterLink>
    ));

  const focusedThemes = themes?.filter((theme) => theme.isFocused) || [];
  const otherThemes = themes?.filter((theme) => !theme.isFocused) || [];

  return (
    <div className="py-2 rounded-lg w-full ">
      <div
        className={`flex justify-between text-indigo-800 p-3 rounded-lg ${
          activeTheme != null ? "bg-green-500/10" : ""
        }`}
        onClick={toggleOnClick}
      >
        <div>
          <p
            className={`text-lg font-semibold ${
              activeTheme ? "text-green-500" : "text-indigo-800"
            }`}
          >
            {t("SharedStrings.Themes")}
          </p>
        </div>

        <div>
          {isOpen ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
        </div>
      </div>
      {isOpen && (
        <div className="px-3 my-2 mt-4 pb-2">
          {/* Focus themes */}
          {focusedThemes.length > 0 && (
            <p className="my-4 text-sm text-slate-400 font-bold cursor-default">
              {t("SharedStrings.Focus")}
            </p>
          )}
          <div className="flex flex-col gap-4 text-sm">
            {renderThemes(focusedThemes)}
          </div>

          {/* Other themes */}
          {focusedThemes.length > 0 && (
            <p className="my-4 text-sm text-slate-400 font-bold cursor-default">
              {t("SharedStrings.Other")}
            </p>
          )}
          <div className="flex flex-col gap-4 text-sm">
            {renderThemes(otherThemes)}
          </div>
        </div>
      )}
    </div>
  );
};

export const ThemesDropdownDesktop = ({ isActive }: { isActive: boolean }) => {
  const { t, pathT } = useTranslatedNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { data: themes = [] } = useQueryThemesCheckins() as {
    data: Theme[] | undefined;
  };

  const focusedThemes = themes.filter((theme) => theme.isFocused);
  const otherThemes = themes.filter((theme) => !theme.isFocused);
  const shouldRenderOtherLabel =
    focusedThemes.length > 0 && otherThemes.length > 0;

  const toggleOnClick = () => setIsOpen(!isOpen);
  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  const renderThemes = (themesList) => {
    return themesList.map((theme) => (
      <RouterLink
        key={theme.key}
        to={pathT("route.themes", {
          key: t(`route.ThemeKey.${theme.key}`),
        })}
      >
        <TypographyWithIcon
          icon={getThemeIcons(theme.key)}
          classNameText="ml-1"
          className="w-full hover:bg-slate-50 text-slate-700 hover:text-indigo-950 p-2 px-4 cursor-pointer"
        >
          <p className="font-semibold ml-2 pt-0.5">
            {t(`Themes.${theme.key}.Name`)}
          </p>
        </TypographyWithIcon>
      </RouterLink>
    ));
  };

  return (
    <div
      className="relative h-full"
      onClick={toggleOnClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`h-full flex items-center px-1 mx-3 text-sm font-bold transition-all text-slate-700 hover:text-indigo-800 border-solid border-x-0 border-t-0 border-b-[3px] border-transparent hover:border-slate-300 cursor-pointer ${isActive && "!border-[#00A885] !hover:border-[#00A885]"}`}
      >
        <span className="mr-1">Themes</span>
        {isOpen ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
      </div>

      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className="-right-10 -mt-1"
      >
        <div className="flex flex-col items-center gap-1 w-56 border-0 rounded-xl shadow-xl p-0 py-2 text-sm">
          <div className="w-full">
            {/* Focused themes */}
            {themes && themes.some((theme) => theme.isFocused) && (
              <>
                <p className="mx-4 my-2 text-sm text-slate-400 font-bold cursor-default">
                  {t("SharedStrings.Focus")}
                </p>
                {renderThemes(themes.filter((theme) => theme.isFocused))}
              </>
            )}

            {shouldRenderOtherLabel && (
              <p className="mx-4 my-2 text-sm text-slate-400 font-bold cursor-default">
                {t("SharedStrings.Other")}
              </p>
            )}

            {/* {Other themes} */}
            {themes && themes.some((theme) => !theme.isFocused) && (
              <>{renderThemes(themes.filter((theme) => !theme.isFocused))}</>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};
