/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Filters from "@openup/shared/components/Filters/Filters";
import {
  ContentEventType,
  useGetInfiniteContent,
  useSaveContentEvent,
} from "@src/queries/contents";
import { useQueryThemesList } from "@src/queries/themes";
import { ReactElement, useEffect, useState } from "react";
import ArticleCard, {
  getLoadingCards,
} from "@src/components/Library/ArticleCard";

import { getThemeIcon } from "@src/utils/getThemeIcon";
import InfiniteScrollingCards from "@openup/shared/components/Cards/InfiniteScrollingCards/InfiniteScrollingCards";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useTranslation } from "react-i18next";

const CARDS_LOADING_AMOUNT = 9;

export const CoursesView = () => {
  const { t, pathT } = useTranslatedNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const sendDataToGTM = useGTMDispatch();
  const [selectedTheme, setSelectedTheme] = useState<string>("");
  const { mutateAsync: saveEvent } = useSaveContentEvent();

  const [courseCards, setCourseCards] = useState<ReactElement[]>(
    getLoadingCards(CARDS_LOADING_AMOUNT),
  );

  const { data: themes } = useQueryThemesList();

  const {
    data: coursesData,
    isLoading: isLoadingCourses,
    isError: isErrorCourses,
    fetchNextPage: fetchNextPageCourses,
    isFetchingNextPage: isFetchingNextPageCourses,
  } = useGetInfiniteContent({ type: "courses", theme: selectedTheme });

  useEffect(() => {
    setCourseCards(getLoadingCards(CARDS_LOADING_AMOUNT));
  }, [isLoadingCourses]);

  useEffect(() => {
    if (coursesData && coursesData?.pages.length > 0) {
      setCourseCards(
        (coursesData || []).pages
          ?.map((page) => page!.data)
          .flat()
          .map((course) => (
            <ArticleCard
              className="w-full"
              key={course.url}
              article={course}
              onMouseUp={(e) => {
                if (e.button === 0 || e.button === 1) {
                  navigate(`${pathT("route.course")}/${course.id}`);
                  sendDataToGTM({
                    event: "My OpenUp - Courses Page - Course Click",
                    language,
                    article_id: course.id,
                  });
                  if (course.id) {
                    saveEvent({
                      contentId: course.id,
                      eventType: ContentEventType.Click,
                    });
                  }
                }
              }}
              isThemeVisible={selectedTheme === ""}
            />
          )),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesData, selectedTheme]);

  return (
    <>
      <div
        onClick={() => navigate(pathT("route.library"))}
        className="flex items-center cursor-pointer mb-4 font-semibold text-indigo-800"
      >
        <span className="flex items-center mr-2">
          <ChevronLeft className="w-5 h-5" />
        </span>
        <span className="text-lg">{t("LibraryView.Title")}</span>
      </div>
      <p className="text-4xl md:text-5xl font-serif font-black text-indigo-800">
        {t("Content.Title.courses")}
      </p>
      <Filters
        list={[
          {
            id: null,
            name: t("ThemesFilter.All"),
            icon: "AppsOutlined",
          },
          ...themes.map(({ ordinal, key }) => ({
            id: ordinal,
            name: t(`Themes.${key}.Name`),
            icon: getThemeIcon(key),
          })),
        ]}
        selectedId={
          themes.find(({ key }) => key === selectedTheme)?.ordinal ?? null
        }
        onClick={(id) => {
          setSelectedTheme(
            themes.find(({ ordinal }) => ordinal === id)?.key ?? "",
          );
        }}
        className="my-8"
      />
      <div>
        {!isLoadingCourses && isErrorCourses ? (
          <p className="h4">{t("InvalidActionView.SomethingWentWrong")}</p>
        ) : (
          <div>
            <InfiniteScrollingCards
              cards={courseCards.concat(
                isFetchingNextPageCourses ? getLoadingCards(9) : [],
              )}
              isLoading={isLoadingCourses}
              onBottomReached={async () => {
                sendDataToGTM({
                  event:
                    "My OpenUp - Courses Page - Load More Courses Triggered",
                  language,
                });
                await fetchNextPageCourses();
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
