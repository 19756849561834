/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import TypographyWithIcon from "@openup/shared/components/TypographyWithIcon/TypographyWithIcon";
import { connect } from "react-redux";
import { logout } from "@src/actions/userActions";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Popover } from "@src/components/Popover";
import { Avatar } from "@src/components/Avatar";

const AvatarPopoverTopbar = ({ logout: logoutUser, authReducers }) => {
  const navigate = useNavigate();
  const { t, pathT } = useTranslatedNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const logoutAndRedirect = () => {
    logoutUser();
    navigate(pathT("route.login"));
  };

  return (
    <div className="relative inline-block text-left" data-cy="avatar">
      <div onClick={() => setIsOpen(!isOpen)} className="h-full cursor-pointer">
        <Avatar
          firstName={authReducers.user?.firstName}
          lastName={authReducers.user?.lastName}
        />
      </div>

      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="flex flex-col items-center gap-1 w-full border-0 rounded-xl shadow-xl p-0 py-2">
          <div
            className="w-full"
            onClick={() => {
              setIsOpen(false);
              navigate(pathT("route.profile"));
            }}
          >
            <TypographyWithIcon
              icon={<AccountCircleOutlinedIcon />}
              className="w-full hover:bg-slate-50 text-slate-700 hover:text-indigo-950 p-2 px-4 cursor-pointer"
            >
              <p className="text-md font-semibold ml-2 pt-0.5">
                {t("SharedStrings.Account")}
              </p>
            </TypographyWithIcon>
          </div>

          <div
            className="w-full"
            onClick={() => {
              setIsOpen(false);
              logoutAndRedirect();
            }}
          >
            <TypographyWithIcon
              icon={<LogoutIcon />}
              className="w-full hover:bg-slate-50 text-slate-700 hover:text-indigo-950 p-2 px-4 cursor-pointer"
            >
              <p className="text-md font-semibold ml-2 pt-0.5">
                {t("SharedStrings.Logout")}
              </p>
            </TypographyWithIcon>
          </div>
        </div>
      </Popover>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
});
const mapStateToProps = (state) => state;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvatarPopoverTopbar);
