/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Logo from "@src/components/Logo";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { logout } from "@src/actions/userActions";
import { useEffect, useState } from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import TypographyWithIcon from "@openup/shared/components/TypographyWithIcon/TypographyWithIcon";
import Button from "@openup/shared/components/Button/Button";
import { connect } from "react-redux";
import { ThemesDropdownMobile } from "@src/components/Drawers/ThemeDropdown";

const ProfileButton = ({ active = false, navigateToAccount = () => {} }) => {
  const { t } = useTranslatedNavigate();

  return (
    <div onClick={navigateToAccount}>
      <TypographyWithIcon
        icon={
          <AccountCircleOutlinedIcon
            className={`mr-3 ${active ? "text-green-500" : "text-indigo-800"}`}
          />
        }
        className={`p-3 rounded-lg w-full text-indigo-800 ${active ? "bg-green-500/10" : ""}`}
      >
        <p
          className={`text-lg leading-normal tracking-tight  font-semibold ${active ? "text-green-500" : "text-indigo-800"}`}
        >
          {t("SharedStrings.Account")}
        </p>
      </TypographyWithIcon>
    </div>
  );
};

const LogoutButton = ({ onClick = () => {} }) => {
  const { t } = useTranslatedNavigate();

  return (
    <div onClick={onClick}>
      <TypographyWithIcon
        icon={<LogoutIcon className="mr-3 text-indigo-800" />}
        className="p-3 rounded-lg w-full text-indigo-800"
      >
        <p className="text-lg leading-normal tracking-tight font-semibold">
          {t("SharedStrings.Logout")}
        </p>
      </TypographyWithIcon>
    </div>
  );
};

const BookSessionButton = ({ title = "", onClick = () => {} }) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white px-4 py-4 shadow-inner">
      <Button className="w-full" onClick={onClick}>
        {title}
      </Button>
    </div>
  );
};

export const MobileTopbar = ({ items, logout: logoutUser }) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const location = useLocation();

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const url = location.pathname;

  const activeUrl = items?.find((item) =>
    location.pathname.includes(item.path),
  )?.path;

  const [activeItem, setActiveItem] = useState(activeUrl);
  const [activeTheme, setActiveTheme] = useState(null);

  const toggleMobileMenu = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };
  const navigateToAccount = () => {
    setActiveItem(pathT("route.profile"));
    setIsNavbarOpen(false);
    navigate(pathT("route.profile"));
  };
  const logoutAction = () => {
    logoutUser();
  };

  useEffect(() => {
    // Lock background body scroll when navbar is open
    if (isNavbarOpen) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = ""; // Restore scroll
    }

    return () => {
      document.body.style.overflow = ""; // Cleanup
    };
  }, [isNavbarOpen]);

  return (
    <div className="sticky top-0 z-30 bg-white shadow-md">
      <div className="flex items-center justify-between px-4 py-6">
        <RouterLink to={pathT("route.account")}>
          <Logo className="" />
        </RouterLink>
        <div className="md:hidden" onClick={toggleMobileMenu}>
          {isNavbarOpen ? (
            <CloseIcon className="w-6 h-6" />
          ) : (
            <MenuIcon className="w-6 h-6" />
          )}
        </div>
      </div>

      {isNavbarOpen && (
        <div
          className="fixed inset-0 top-[72px] z-20 bg-white flex flex-col overflow-y-auto"
          style={{ maxHeight: "calc(100vh - 72px)" }} // Ensure menu doesn't exceed viewport height
        >
          <div className="flex flex-col w-full px-4 gap-2 pt-6 shadow-inner">
            {items.map((item) => (
              <div
                key={item.path}
                className={`p-3 rounded-lg ${activeItem === item.path && !activeTheme ? "bg-green-500/10" : ""}`}
                onClick={() => {
                  toggleMobileMenu();
                  setActiveItem(item.path);
                  setActiveTheme(null);
                  navigate(item.path);
                }}
              >
                <div
                  className={`text-lg font-semibold leading-normal tracking-tight ${activeItem === item.path && !activeTheme ? "text-green-500" : "text-indigo-800"}`}
                >
                  {item.title}
                </div>
              </div>
            ))}
            <ThemesDropdownMobile
              closeMenu={toggleMobileMenu}
              activeTheme={activeTheme}
              setActiveTheme={setActiveTheme}
            />

            <div className="h-px bg-slate-200" />
          </div>

          <div className="flex flex-col w-full px-4 mt-4 gap-1 mb-24">
            <ProfileButton
              active={url === pathT("route.profile")}
              navigateToAccount={navigateToAccount}
            />
            <LogoutButton onClick={logoutAction} />
          </div>

          <BookSessionButton
            title={t("DashboardView.BookOnlineConsult")}
            onClick={() => {
              navigate(pathT("route.booking"));
              setIsNavbarOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
});

export default connect(null, mapDispatchToProps)(MobileTopbar);
