import useTranslatedNavigate from "@src/services/useTranslateNavigate";
// eslint-disable-next-line import/no-unresolved
import { MobileTopbar } from "@src/components/Drawers/MobileTopBar";
import { FullWidthTopBar } from "@src/components/Drawers/FullWidthTopBar";

export const StickyTopNavBar = () => {
  const { pathT, t } = useTranslatedNavigate();

  const menuItems = [
    {
      title: t("SharedStrings.Home"),
      path: pathT("route.account"),
    },
    {
      title: t("SharedStrings.Library"),
      path: pathT("route.library"),
    },
    {
      title: t("SharedStrings.MySessions"),
      path: pathT("route.mySessions"),
    },
    {
      title: t("SharedStrings.Chekins"),
      path: pathT("route.checkins"),
    },
  ];

  return (
    <div className="sticky top-0 z-10">
      <div className="hidden md:block">
        <FullWidthTopBar items={menuItems} />
      </div>
      <div className="block md:hidden">
        <MobileTopbar items={menuItems} logout={undefined} />
      </div>
    </div>
  );
};
