/* eslint-disable react/jsx-props-no-spreading */
import { FooterBar } from "@src/components/FooterBar";
import React, { forwardRef } from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import { StickyTopNavBar } from "@src/components/Drawers/StickyTopNavBar";

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  backgroundColor?: string;
  title?: string;
}

export const BaseLayout = forwardRef<HTMLDivElement, PageProps>(
  ({ title = "", className = "", ...rest }, ref) => {
    return (
      <div
        ref={ref}
        {...rest}
        className={`flex flex-col min-h-screen bg-orange-100 ${className}`}
      >
        <StickyTopNavBar />
        <main className="flex-grow container px-4 py-8 md:p-0 mx-auto max-w-screen-xl md:py-20">
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Outlet />
        </main>
        <FooterBar />
      </div>
    );
  },
);
