import { useRef, useEffect } from "react";

export const Popover = ({ isOpen, onClose, className = "", children }) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose?.();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div
      ref={popoverRef}
      className={`absolute right-0 bg-white border border-gray-200 rounded-xl shadow-lg z-10 ${className}`}
    >
      {children}
    </div>
  );
};
