import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

export const Avatar = ({
  firstName = undefined,
  lastName = undefined,
}: {
  firstName: string | undefined;
  lastName: string | undefined;
}) => {
  const getInitials = (first: string | undefined, last: string | undefined) => {
    const trimmedFirstName = first?.trim();
    const trimmedLastName = last?.trim();

    if (trimmedFirstName && trimmedLastName) {
      return (
        trimmedFirstName.charAt(0).toUpperCase() +
        trimmedLastName.charAt(0).toUpperCase()
      );
    }

    return null;
  };

  const initials = getInitials(firstName, lastName);

  return (
    <div className="bg-green-500/10 text-green-500 flex items-center justify-center rounded-full w-10 h-10 font-bold">
      {initials ? <div>{initials}</div> : <AccountCircleOutlinedIcon />}
    </div>
  );
};
