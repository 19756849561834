import ContentCard, {
  ContentCardProps,
} from "@openup/shared/components/Cards/ContentCard/ContentCard";
import { ContentItem } from "@src/queries/contents";
import { decodeHtmlSpecialCharacters } from "@src/utils/formatting";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

interface ArticleCardProps {
  article: ContentItem;
  variant?: ContentCardProps["variant"];
  isThemeVisible?: boolean;
  isBackgroundEnabled?: boolean;
  onMouseUp?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}

const ArticleCard = ({
  article: {
    title,
    url,
    thumbnailPath,
    readTime,
    publishedDate,
    // temporary show contenty-type instead of theme (OI-8398)
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    themes: contentTopic,
    type,
  },
  variant = "standard",
  isThemeVisible = true,
  isBackgroundEnabled = true,
  onMouseUp,
  className = "",
}: ArticleCardProps) => {
  const { t } = useTranslation();

  const getMinutesToRead = () => {
    if (type.includes("course")) {
      return readTime
        ? t("SharedStrings.EstimatedMinutes", {
            time: Math.round(readTime / 60),
          })
        : "";
    }
    return readTime
      ? t("SharedStrings.EstimatedMinutes", { time: readTime })
      : "";
  };

  return (
    <ContentCard
      key={`${publishedDate}-${url}`}
      title={decodeHtmlSpecialCharacters(title)}
      href={url}
      imageSrc={thumbnailPath}
      minsToRead={getMinutesToRead()}
      onMouseUp={onMouseUp}
      topic={isThemeVisible ? t(`ContentCard.Type.${type}`) : undefined}
      isLoading={false}
      target="_blank"
      variant={variant}
      isBackgroundEnabled={isBackgroundEnabled}
      className={`${className} h-full`}
    />
  );
};

const getLoadingCards = (amount) =>
  Array.from({ length: amount }, (_, index) => (
    <ContentCard
      key={index}
      title={undefined}
      href={undefined}
      imageSrc={undefined}
      isLoading
    />
  ));

export default ArticleCard;
export { getLoadingCards };
