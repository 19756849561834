/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Logo from "@src/components/Logo";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useState } from "react";
import AvatarPopoverTopbar from "@src/layouts/BaseLayouts/AvatarPopoverTopbar";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ThemesDropdownDesktop } from "@src/components/Drawers/ThemeDropdown";

export const FullWidthTopBar = ({
  items,
}: {
  items: { title: string; path: string }[];
}) => {
  const { t, pathT } = useTranslatedNavigate();
  const location = useLocation();

  const isThemeUrl = location.pathname.includes(
    t("route.onboarding.focus_themes"),
  );

  const isUrlInItems = items.some((item) =>
    location.pathname.includes(item.path),
  );

  const activeUrl =
    items.find((item) => location.pathname.includes(item.path))?.path ||
    pathT("route.account");

  const [activeItem, setActiveItem] = useState(activeUrl);

  return (
    <div className="h-[72px] sticky top-0 z-10 bg-white shadow-md">
      <div className="container max-w-screen-xl mx-auto flex items-center justify-between h-full">
        <div className="flex items-center justify-center h-full">
          <RouterLink to={pathT("route.account")}>
            <Logo className="w-32 h-32 mt-2 mr-6" showLanguageSpecificIcon />
          </RouterLink>
          {items.map((item) => (
            <RouterLink
              key={item.path}
              to={item.path}
              onClick={() => setActiveItem(item.path)}
              className={`h-full flex items-center px-1 mx-3 text-sm font-bold transition-all text-slate-700 hover:text-indigo-800 border-solid border-x-0 border-t-0 border-b-[3px] border-transparent hover:border-slate-300 ${isUrlInItems && activeItem === item.path && "!border-[#00A885] !hover:border-[#00A885]"}`}
            >
              <p>{item.title}</p>
            </RouterLink>
          ))}
          <ThemesDropdownDesktop isActive={isThemeUrl} />
        </div>
        <AvatarPopoverTopbar logout={undefined} authReducers={undefined} />
      </div>
    </div>
  );
};
